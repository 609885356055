<template>
    <div class="newsletter-form">
        <transition name="fade" mode="out-in">
            <form v-if="!success" @submit.prevent="subscribe" key="init">
                <div class="newsletter-form__input-group">
                    <div class="form-group">
                        <input
                            type="email"
                            :placeholder="$t('Form.email.placeholder')"
                            v-model.lazy="email"
                            class="input-gray form-control newsletter-form__input"
                            required
                        />
                        <IconSvg filepath="mail.svg" class="newsletter-form__email-icon" />
                    </div>

                    <button
                        type="submit"
                        :disabled="submitting"
                        class="btn btn-primary btn-block newsletter-form__button mb-3"
                    >
                        <template v-if="!buttonText">
                            <media-only desktop tag="span">
                                {{ $t('Newsletter.button', { amount: 10 }) }}
                            </media-only>
                            <media-only mobile tag="span">
                                {{ $t('Newsletter.button.mobile') }}
                            </media-only>
                        </template>
                        <span v-else>{{ buttonText }}</span>
                    </button>
                </div>

                <div class="newsletter-form__notice">{{ $t('Newsletter.notice.cancel') }}</div>
                <small class="newsletter-form__info">{{ $t('Newsletter.notice.conditions', { amount: 80 }) }}</small>
            </form>

            <div v-else class="newsletter-form__success-text" key="success">
                <template v-if="alreadySubscribed">
                    {{ $t('Newsletter.success.alreadySubscribed') }}
                </template>
                <template v-else>
                    {{ $t('Newsletter.success.text') }}
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'NewsletterForm',
    data() {
        return {
            email: '',
            //flags
            submitting: false,
            alreadySubscribed: false,
            success: false,
            error: false,
            notSubscribed: false
        };
    },
    props: {
        buttonText: {
            type: String
        },
        source: {
            type: String
        }
    },
    methods: {
        async subscribe() {
            this.submitting = true;
            this.error = false;
            this.success = false;
            this.notSubscribed = false;
            this.alreadySubscribed = false;

            const email = this.email;
            const source = this.source;

            try {
                const { data: response } = await this.$axios.post('shop/subscribe', {
                    email,
                    source
                });

                this.success = true;

                if (response && response.currentStatus === 'subscribed') {
                    this.alreadySubscribed = true;
                }
            } catch (e) {
                this.error = true;

                if (e.response && this.$getSafe(e, 'response.data.status') === 'not_subscribed') {
                    this.notSubscribed = true;
                }
            } finally {
                this.submitting = false;
            }
        }
    },
    watch: {
        success(value) {
            this.$emit('success', value);
        },
        error(value) {
            this.$emit('error', value);
        },
        notSubscribed(value) {
            this.$emit('response:not-subscribed', value);
        },
        alreadySubscribed(value) {
            this.$emit('response:already-subscribed', value);
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/mixins';

.newsletter-form {
    max-width: 470px;
    display: inline-flex;

    &__input {
        text-align: center;
        font-size: 18px;
        line-height: 1.5;
        padding-left: 40px;
        padding-right: 40px;
    }

    &__email-icon {
        position: absolute;
        left: variables.$grid-padding;
        top: 50%;
        margin-top: -(variables.$grid-padding);
        font-size: 30px;
        color: variables.$muted;
        z-index: 5;
        stroke: currentColor;
    }

    &__button {
        text-transform: uppercase;
        padding: 0.75em;
        text-align: center;
        font-weight: bold;
    }

    &__notice {
        line-height: 1;
    }

    &__info {
        font-size: 0.7em;
        line-height: 1.2em;
        color: variables.$muted;
        display: block;
        margin-top: 5px;
    }

    &__success-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-width: 650px;
        margin: 0 auto;
        min-height: 180px;
    }

    @include mixins.mobile-only {
        &__notice,
        &__info {
            font-size: 1rem;
        }
    }
}

.form-group {
    position: relative;
}

@include mixins.transition-fade;
</style>
