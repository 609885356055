<template>
    <div class="newsletter">
        <div class="newsletter-teaser">
            <NewsletterBadge class="newsletter__badge ml-3 ml-lg-5">
                <transition name="fade" mode="out-in">
                    <translate
                        v-if="!success || alreadySubscribed"
                        path="Newsletter.badge"
                        :params="{ amount: 10 }"
                        key="badge-init"
                    />
                    <translate v-else path="Newsletter.success.badge" key="badge-success" />
                </transition>
            </NewsletterBadge>
        </div>

        <div class="newsletter-header">
            <p class="header text-center">
                <transition name="fade" mode="out-in">
                    <translate
                        v-if="success && !alreadySubscribed"
                        path="Newsletter.success.header"
                        key="header-success"
                    />
                    <translate
                        v-else-if="error && notSubscribed"
                        path="Newsletter.error.not_subscribed"
                        key="header-not-subscribed"
                    />
                    <translate v-else-if="error" path="Generic.tryAgain" key="header-error" />
                    <translate v-else path="Newsletter.header" key="header-init" />
                </transition>
            </p>
        </div>

        <div class="newsletter-body">
            <NewsletterForm
                @success="success = $event"
                @error="error = $event"
                @response:not-subscribed="notSubscribed = $event"
                @response:already-subscribed="alreadySubscribed = $event"
                :source="newsletterSource"
                class="form"
            />
        </div>

        <slot></slot>
    </div>
</template>

<script>
import Modal from '@/components/Modal';
import NewsletterForm from '@/components/newsletter/NewsletterForm';
import NewsletterBadge from '@/components/newsletter/NewsletterBadge';

export default {
    name: 'Newsletter',
    components: {
        NewsletterBadge,
        NewsletterForm,
        Modal
    },
    computed: {
        newsletterSource() {
            if (this.$route.params.source) {
                return this.$route.params.source;
            } else if (this.$route.name === 'index') {
                return 'StartpageForm';
            } else if (this.$route.name === 'category') {
                return 'CategoryPage';
            } else if (this.$route.meta.isDetailsRoute) {
                return 'ProductDetailsPage';
            }
            return 'NewsletterForm';
        }
    },
    data() {
        return {
            error: false,
            notSubscribed: false,
            success: false,
            alreadySubscribed: false
        };
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;
@use '@/styles/_mixins';

.newsletter {
    box-shadow: 0 -7px 18px 0px rgba(0, 0, 0, 0.1), 7px 7px 18px 0 rgba(0, 0, 0, 0.1);

    @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
        margin-left: -(variables.$grid-padding);
        margin-right: -(variables.$grid-padding);
    }

    .newsletter-teaser {
        @include mixins.local-image(
            url('../../assets/webp/newsletter/bg_newsletter_form_embedded_v2.webp'),
            url('../../assets/newsletter/bg_newsletter_form_embedded_v2.jpg')
        );
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 180px;
        position: relative;
        z-index: 3;
    }

    &__badge {
        top: -20px;
        position: absolute;
    }

    .newsletter-header {
        background: var(--newsletter-header-bg);
        padding: 10px;

        .header {
            color: var(--newsletter-header-color);
            font-size: 18px;
            margin-bottom: 0;
            line-height: 1.5em;
            font-weight: 500;
        }
    }

    .newsletter-body {
        background-color: var(--footer-bg-color);

        text-align: center;
        padding: variables.$grid-padding;
        z-index: 2;
        position: relative;
        height: 220px;
        overflow: hidden;

        .form ::v-deep {
            .newsletter-form__notice,
            .newsletter-form__success-text {
                color: var(--footer-header-color);
            }
        }
    }

    @include mixins.transition-fade;
}
</style>
