<template>
    <transition name="modal" @after-leave="afterLeave" appear @appear="isHidden">
        <div
            class="modal-mask"
            tabindex="0"
            ref="modal"
            @keyup.esc="closeOnEscape && close()"
            @click.self="dismissible && close()"
            :class="{ 'modal-mask--centered': centered }"
        >
            <div class="modal-wrapper" role="document" :style="{ width: `${width}px` }">
                <div class="modal-container">
                    <div v-if="$slots.header" class="modal-header">
                        <!-- @slot Use this slot header -->
                        <slot name="header"> </slot>
                    </div>
                    <div class="modal-body" :class="{ 'p-0': noPadding }">
                        <!-- @slot Use this default slot for the modal body -->
                        <slot></slot>
                    </div>
                    <div v-if="!hideFooter" class="modal-footer">
                        <!-- @slot Use this slot footer -->
                        <slot name="footer">
                            <button type="button" class="btn btn-info text-uppercase" @click.stop="close">
                                {{ closeText || $t('Generic.close') }}
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
    name: 'Modal',
    data() {
        return {
            hidden: false
        };
    },
    props: {
        width: {
            type: Number,
            default: 1000
        },
        centered: Boolean,
        closeOnEscape: Boolean,
        closeText: String,
        dismissible: Boolean,
        hideFooter: Boolean,
        noPadding: Boolean
    },
    methods: {
        afterLeave() {
            if (document.body.contains(this.$el)) {
                this.$el.parentNode.removeChild(this.$el);
            }
        },
        close() {
            this.$emit('close');
        },
        isHidden(el) {
            const elementToCheck = el.closest('.media-only');
            if (elementToCheck) {
                this.hidden = getComputedStyle(elementToCheck).display === 'none';
            } else this.hidden = false;
        },
        recalculateMaskHeight() {
            this.$el.style.height = window.innerHeight + 'px'; //fix for changed height with/without toolbar on iOS/address bar on Android
        }
    },
    mounted() {
        //Should modal be reactive to screen size change / rotation => wrap teleported content with parent media-only equivalent
        !this.hidden &&
            this.$nextTick(() => {
                document.body.appendChild(this.$el);
                this.$el.focus();
                this.recalculateMaskHeight();

                this.$root.$scrollToIframeTop && this.$root.$scrollToIframeTop();

                disableBodyScroll(this.$refs.modal, { reserveScrollBarGap: true });

                window.addEventListener('resize', this.recalculateMaskHeight);

                const removeFn = this.$router.beforeEach(async (to, from, next) => {
                    if (!this.dismissible) {
                        next(false);
                    } else {
                        this.close();
                        await this.$nextTick();
                        next();
                    }
                });
                this.$on('hook:beforeDestroy', () => {
                    removeFn();
                });

                this.$emit('opened', this.$el);
            });
    },
    beforeDestroy() {
        if (!this.hidden) {
            window.removeEventListener('resize', this.recalculateMaskHeight);
            enableBodyScroll(this.$refs.modal);
        }
    }
};
</script>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;
@use '@/styles/theming' as *;

.modal-mask {
    position: fixed;
    z-index: map.get(variables.$z-indices, 'modal');
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.3);
    transition: opacity 0.3s ease;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;

    &--centered {
        display: flex;
        align-items: center;
    }

    .modal-wrapper {
        display: flex;
        max-height: calc(100% - 3.5rem);
        max-width: 90%;
        margin: 1.75rem auto;
        position: relative;
        pointer-events: none;

        .modal-container {
            background-color: var(--white);

            .modal-header {
                border-bottom: 1px solid var(--border-color);
            }

            .modal-footer {
                border-top: 1px solid var(--border-color);
            }
            max-height: 90%;
            overflow: hidden;
            width: 100%;
            display: flex;
            flex-direction: column;
            pointer-events: auto;
            background-clip: padding-box;
            border-radius: 2px;
            outline: 0;
            box-shadow: 0 2px 8px hsla(0, 0%, 0%, 0.33);
            transition: all 0.3s ease;
            position: relative;

            /*overscroll-behavior: none;*/

            .modal-header,
            .modal-footer {
                flex-shrink: 0;
            }

            .modal-header {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding: variables.$grid-padding;

                > :last-child {
                    margin-bottom: 0 !important;
                }
            }

            .modal-body {
                overflow-y: auto;
                overflow-x: hidden;
                position: relative;
                flex: 1 1 auto;
                padding: variables.$grid-padding;
            }

            .modal-footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: variables.$grid-padding;
            }
        }
    }
}

//Transition styles
.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    transform: scale(1.1);
}
</style>
