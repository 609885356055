<template>
    <div class="newsletter-badge">
        <slot>
            <translate path="Newsletter.badge" :params="{ amount: 10 }" />
        </slot>
    </div>
</template>

<script>
export default {
    name: 'NewsletterBadge'
};
</script>

<style lang="scss" scoped>
.newsletter-badge {
    background-color: var(--primary);
    color: var(--white);
    padding: 15px 10px 25px;
    white-space: nowrap;
    font-weight: bold;
    font-size: 20px;
    border-radius: 150px/20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 5px 5px 10px 0 hsla(0, 0%, 0%, 0.25);
    min-width: 250px;
    text-align: center;
    overflow: hidden;
}
</style>
