// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/newsletter/bg_newsletter_form_embedded_v2.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/webp/newsletter/bg_newsletter_form_embedded_v2.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newsletter[data-v-0b0cf1f3]{box-shadow:0 -7px 18px 0 rgba(0,0,0,.1),7px 7px 18px 0 rgba(0,0,0,.1)}@media(max-width:991.98px){.newsletter[data-v-0b0cf1f3]{margin-left:-15px;margin-right:-15px}}.newsletter .newsletter-teaser[data-v-0b0cf1f3]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover;height:180px;position:relative;z-index:3}.web-p .newsletter .newsletter-teaser[data-v-0b0cf1f3]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.newsletter__badge[data-v-0b0cf1f3]{position:absolute;top:-20px}.newsletter .newsletter-header[data-v-0b0cf1f3]{background:var(--newsletter-header-bg);padding:10px}.newsletter .newsletter-header .header[data-v-0b0cf1f3]{color:var(--newsletter-header-color);font-size:18px;font-weight:500;line-height:1.5em;margin-bottom:0}.newsletter .newsletter-body[data-v-0b0cf1f3]{background-color:var(--footer-bg-color);height:220px;overflow:hidden;padding:15px;position:relative;text-align:center;z-index:2}.newsletter .newsletter-body .form[data-v-0b0cf1f3] .newsletter-form__notice,.newsletter .newsletter-body .form[data-v-0b0cf1f3] .newsletter-form__success-text{color:var(--footer-header-color)}.newsletter .fade-enter-active[data-v-0b0cf1f3],.newsletter .fade-leave-active[data-v-0b0cf1f3]{transition:all .1s ease-in-out}.newsletter .fade-enter-to[data-v-0b0cf1f3]{opacity:1}.newsletter .fade-enter[data-v-0b0cf1f3],.newsletter .fade-leave-to[data-v-0b0cf1f3]{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
